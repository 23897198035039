import { HttpLink, ApolloClient, InMemoryCache, from, fromPromise, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from 'apollo-link-error';

import packageJson from '../../package.json';
import { getGraphQLTokens } from '../api/graphql';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

// Configuration for version and GraphQL API URL
const { version } = packageJson;
const { NODE_ENV, REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL } = process.env;
const GRAPHQL_TOKENS_STORAGE_KEY = 'uta.graphQLTokens';

const IS_DEV = NODE_ENV === 'development';

// Control variables for token refreshing
let isRefreshing = false;
let pendingRequests = [];

// Resolves all pending requests after the token refresh is complete
const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback());
  pendingRequests = [];
};

// Create the HttpLink for communication with the GraphQL API
const httpLink = new HttpLink({
  uri: REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL,
});

let timedOut;
let activeSocket;

const wsLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_CHAMELEON_GRAPHQL_API_BASE_URL.replace(/^http(s)*:\/\//gi, 'ws$1://'),
    retryAttempts: Infinity,
    shouldRetry: () => true,
    keepAlive: 10000,
    on: {
      connected: (socket) => (activeSocket = socket),
      ping: (received) => {
        if (!received) {
          // sent
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN) {
              activeSocket.close(4408, 'Request Timeout');
            }
          }, 5000);
        } // wait 5 seconds for the pong and then close the connection
      },
      pong: (received) => {
        if (received) {
          clearTimeout(timedOut);
        } // pong is received, clear connection close timeout
      },
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

// Function to prepare authentication headers for requests
const prepareAuthHeaders = () => {
  const graphQLTokensString = localStorage.getItem(GRAPHQL_TOKENS_STORAGE_KEY);

  let graphQLTokens = null;
  if (graphQLTokensString) {
    graphQLTokens = JSON.parse(graphQLTokensString);
  }

  if (graphQLTokens) {
    return {
      interface: 'phonesheet-web',
      Authorization: `Bearer ${graphQLTokens.jwtToken}`,
      'Ocp-Apim-Subscription-Key': graphQLTokens.apimToken,
    };
  }

  return {};
};

// Authentication middleware to add headers to requests
const authMiddleware = setContext(async () => {
  const headers = prepareAuthHeaders();

  const storedUserEmail = localStorage.getItem('uta.user_email');
  if (storedUserEmail) {
    headers.user_email = storedUserEmail;
  }

  const storedAzureId = localStorage.getItem('uta.azure_id');
  if (storedAzureId) {
    headers.azure_id = storedAzureId;
  }

  const storedOutlookPermissions = localStorage.getItem('uta.outlook_permissions');
  if (storedOutlookPermissions) {
    headers.outlook_permissions = storedOutlookPermissions;
  }

  return { headers };
});

// Function to handle network errors, specifically token refresh on 401 errors
const handleNetworkError = (networkError, operation, forward) => {
  let retryOperation;

  if (networkError.statusCode === 401) {
    if (!isRefreshing) {
      isRefreshing = true;

      // Attempt to refresh the tokens and resolve pending requests
      retryOperation = fromPromise(
        getGraphQLTokens()
          .then((response) => {
            const { body: graphQLTokens } = response || {};
            localStorage.setItem(GRAPHQL_TOKENS_STORAGE_KEY, JSON.stringify(graphQLTokens));
            resolvePendingRequests();
            return graphQLTokens;
          })
          .catch(() => {
            localStorage.removeItem(GRAPHQL_TOKENS_STORAGE_KEY);
            pendingRequests = [];
          })
          .finally((tokens) => {
            isRefreshing = false;
            return tokens;
          })
      ).filter(Boolean); // Filter out falsy values
    } else {
      // If already refreshing, wait for the process to complete
      retryOperation = fromPromise(new Promise((resolve) => pendingRequests.push(() => resolve())));
    }

    return retryOperation.flatMap(() => forward(operation));
  }

  return null;
};

// Error handling middleware for GraphQL and network errors
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (IS_DEV && graphQLErrors) {
    console.log('[GraphQL errors]:', graphQLErrors);
  }

  if (networkError) {
    return handleNetworkError(networkError, operation, forward);
  }

  return null;
});

// Function to create the Apollo Client instance
const apolloClient = new ApolloClient({
  version,
  name: 'phonesheet-web',
  link: from([errorLink, authMiddleware, splitLink]),
  cache: new InMemoryCache({
    addTypename: false, // Optionally disable automatic typename addition
  }),
});

export default apolloClient;
