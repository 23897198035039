'use strict';
var __makeTemplateObject =
  (this && this.__makeTemplateObject) ||
  function (cooked, raw) {
    if (Object.defineProperty) {
      Object.defineProperty(cooked, 'raw', { value: raw });
    } else {
      cooked.raw = raw;
    }
    return cooked;
  };
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s) {
            if (Object.prototype.hasOwnProperty.call(s, p)) {
              t[p] = s[p];
            }
          }
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };
Object.defineProperty(exports, '__esModule', { value: true });
exports.DeskMutatedDocument =
  exports.UserPhoneSheetFilterDocument =
  exports.GetPeopleDocument =
  exports.DeskDocument =
  exports.DeskStatusesDocument =
  exports.CallRecordsDocument =
  exports.UpdateUserPhoneSheetFiltersDocument =
  exports.UpdateStatusDocument =
  exports.UpdateDeskDocument =
  exports.UpdateCallRecordsStatusDocument =
  exports.UpdateCallRecordDocument =
  exports.DeleteStatusDocument =
  exports.DeleteCallRecordDocument =
  exports.CreateCallRecordDocument =
  exports.BulkDeleteCallRecordDocument =
    void 0;
exports.useBulkDeleteCallRecordMutation = useBulkDeleteCallRecordMutation;
exports.useCreateCallRecordMutation = useCreateCallRecordMutation;
exports.useDeleteCallRecordMutation = useDeleteCallRecordMutation;
exports.useDeleteStatusMutation = useDeleteStatusMutation;
exports.useUpdateCallRecordMutation = useUpdateCallRecordMutation;
exports.useUpdateCallRecordsStatusMutation = useUpdateCallRecordsStatusMutation;
exports.useUpdateDeskMutation = useUpdateDeskMutation;
exports.useUpdateStatusMutation = useUpdateStatusMutation;
exports.useUpdateUserPhoneSheetFiltersMutation = useUpdateUserPhoneSheetFiltersMutation;
exports.useCallRecordsQuery = useCallRecordsQuery;
exports.useCallRecordsLazyQuery = useCallRecordsLazyQuery;
exports.useCallRecordsSuspenseQuery = useCallRecordsSuspenseQuery;
exports.useDeskStatusesQuery = useDeskStatusesQuery;
exports.useDeskStatusesLazyQuery = useDeskStatusesLazyQuery;
exports.useDeskStatusesSuspenseQuery = useDeskStatusesSuspenseQuery;
exports.useDeskQuery = useDeskQuery;
exports.useDeskLazyQuery = useDeskLazyQuery;
exports.useDeskSuspenseQuery = useDeskSuspenseQuery;
exports.useGetPeopleQuery = useGetPeopleQuery;
exports.useGetPeopleLazyQuery = useGetPeopleLazyQuery;
exports.useGetPeopleSuspenseQuery = useGetPeopleSuspenseQuery;
exports.useUserPhoneSheetFilterQuery = useUserPhoneSheetFilterQuery;
exports.useUserPhoneSheetFilterLazyQuery = useUserPhoneSheetFilterLazyQuery;
exports.useUserPhoneSheetFilterSuspenseQuery = useUserPhoneSheetFilterSuspenseQuery;
exports.useDeskMutatedSubscription = useDeskMutatedSubscription;
var client_1 = require('@apollo/client');
var Apollo = require('@apollo/client');
var defaultOptions = {};
exports.BulkDeleteCallRecordDocument = (0, client_1.gql)(
  templateObject_1 ||
    (templateObject_1 = __makeTemplateObject(
      [
        '\n    mutation BulkDeleteCallRecord($input: CallRecordBulkDeleteInput!) {\n  bulkDeleteCallRecord(input: $input) {\n    success\n    recordIds\n  }\n}\n    ',
      ],
      [
        '\n    mutation BulkDeleteCallRecord($input: CallRecordBulkDeleteInput!) {\n  bulkDeleteCallRecord(input: $input) {\n    success\n    recordIds\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useBulkDeleteCallRecordMutation__
 *
 * To run a mutation, you first call `useBulkDeleteCallRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteCallRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteCallRecordMutation, { data, loading, error }] = useBulkDeleteCallRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useBulkDeleteCallRecordMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.BulkDeleteCallRecordDocument, options);
}
exports.CreateCallRecordDocument = (0, client_1.gql)(
  templateObject_2 ||
    (templateObject_2 = __makeTemplateObject(
      [
        '\n    mutation CreateCallRecord($input: CallRecordInput!) {\n  createCallRecord(input: $input) {\n    contactInfo\n    contact {\n      _id\n      contact\n      contactType\n      private\n    }\n    createdAt\n    description\n    extension\n    notes {\n      _id\n      createdAt\n      createdBy\n      note\n      updatedAt\n      updatedBy\n    }\n    occurrence_date\n    recipientId\n    recipientName\n    status\n    updatedAt\n    companyName\n  }\n}\n    ',
      ],
      [
        '\n    mutation CreateCallRecord($input: CallRecordInput!) {\n  createCallRecord(input: $input) {\n    contactInfo\n    contact {\n      _id\n      contact\n      contactType\n      private\n    }\n    createdAt\n    description\n    extension\n    notes {\n      _id\n      createdAt\n      createdBy\n      note\n      updatedAt\n      updatedBy\n    }\n    occurrence_date\n    recipientId\n    recipientName\n    status\n    updatedAt\n    companyName\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useCreateCallRecordMutation__
 *
 * To run a mutation, you first call `useCreateCallRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCallRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCallRecordMutation, { data, loading, error }] = useCreateCallRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useCreateCallRecordMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.CreateCallRecordDocument, options);
}
exports.DeleteCallRecordDocument = (0, client_1.gql)(
  templateObject_3 ||
    (templateObject_3 = __makeTemplateObject(
      [
        '\n    mutation DeleteCallRecord($id: String!) {\n  deleteCallRecord(_id: $id) {\n    success\n    recordId\n  }\n}\n    ',
      ],
      [
        '\n    mutation DeleteCallRecord($id: String!) {\n  deleteCallRecord(_id: $id) {\n    success\n    recordId\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useDeleteCallRecordMutation__
 *
 * To run a mutation, you first call `useDeleteCallRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCallRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCallRecordMutation, { data, loading, error }] = useDeleteCallRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useDeleteCallRecordMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.DeleteCallRecordDocument, options);
}
exports.DeleteStatusDocument = (0, client_1.gql)(
  templateObject_4 ||
    (templateObject_4 = __makeTemplateObject(
      [
        '\n    mutation DeleteStatus($statuses: [DeskStatusInput!]!) {\n  deleteStatus(statuses: $statuses) {\n    message\n    success\n  }\n}\n    ',
      ],
      [
        '\n    mutation DeleteStatus($statuses: [DeskStatusInput!]!) {\n  deleteStatus(statuses: $statuses) {\n    message\n    success\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useDeleteStatusMutation__
 *
 * To run a mutation, you first call `useDeleteStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatusMutation, { data, loading, error }] = useDeleteStatusMutation({
 *   variables: {
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
function useDeleteStatusMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.DeleteStatusDocument, options);
}
exports.UpdateCallRecordDocument = (0, client_1.gql)(
  templateObject_5 ||
    (templateObject_5 = __makeTemplateObject(
      [
        '\n    mutation UpdateCallRecord($id: String!, $input: CallRecordUpdatePayload!, $azureId: String!, $email: String!) {\n  updateCallRecord(_id: $id, input: $input) {\n    callRecord {\n      _id\n      deskId\n      recipientType\n      recipientName\n      companyName\n      status\n      occurrence_date\n      contactInfo\n      extension\n      favorite\n      notes {\n        _id\n        note\n      }\n      contact {\n        contact\n        contactType\n        private\n        description\n      }\n      recipient(azure_id: $azureId, email: $email) {\n        _id\n        canEdit\n        type\n        name\n        profile_pic\n        outlook\n        title\n        directDial\n        verified\n        verifiedByName\n        verifiedOn\n        groupMembership {\n          group\n        }\n        companies {\n          _id\n          name\n          type\n        }\n        contacts {\n          _id\n          contact\n          contactType\n          description\n          primary\n          private\n          source\n        }\n      }\n      person {\n        _id\n        directDial\n        profile_pic\n        type\n        verified\n        groupMembership {\n          _id\n          group\n          primary\n        }\n        contacts {\n          contact\n          contactType\n          primary\n          _id\n        }\n      }\n    }\n    message\n    success\n  }\n}\n    ',
      ],
      [
        '\n    mutation UpdateCallRecord($id: String!, $input: CallRecordUpdatePayload!, $azureId: String!, $email: String!) {\n  updateCallRecord(_id: $id, input: $input) {\n    callRecord {\n      _id\n      deskId\n      recipientType\n      recipientName\n      companyName\n      status\n      occurrence_date\n      contactInfo\n      extension\n      favorite\n      notes {\n        _id\n        note\n      }\n      contact {\n        contact\n        contactType\n        private\n        description\n      }\n      recipient(azure_id: $azureId, email: $email) {\n        _id\n        canEdit\n        type\n        name\n        profile_pic\n        outlook\n        title\n        directDial\n        verified\n        verifiedByName\n        verifiedOn\n        groupMembership {\n          group\n        }\n        companies {\n          _id\n          name\n          type\n        }\n        contacts {\n          _id\n          contact\n          contactType\n          description\n          primary\n          private\n          source\n        }\n      }\n      person {\n        _id\n        directDial\n        profile_pic\n        type\n        verified\n        groupMembership {\n          _id\n          group\n          primary\n        }\n        contacts {\n          contact\n          contactType\n          primary\n          _id\n        }\n      }\n    }\n    message\n    success\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useUpdateCallRecordMutation__
 *
 * To run a mutation, you first call `useUpdateCallRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallRecordMutation, { data, loading, error }] = useUpdateCallRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      azureId: // value for 'azureId'
 *      email: // value for 'email'
 *   },
 * });
 */
function useUpdateCallRecordMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.UpdateCallRecordDocument, options);
}
exports.UpdateCallRecordsStatusDocument = (0, client_1.gql)(
  templateObject_6 ||
    (templateObject_6 = __makeTemplateObject(
      [
        '\n    mutation UpdateCallRecordsStatus($input: [CallRecordStatusUpdateInput!]!, $email: String!, $azureId: String!) {\n  updateCallRecordsStatus(input: $input) {\n    callRecord {\n      _id\n      deskId\n      recipientType\n      recipientName\n      companyName\n      status\n      occurrence_date\n      contactInfo\n      extension\n      favorite\n      notes {\n        _id\n        note\n      }\n      contact {\n        contact\n        contactType\n        private\n        description\n      }\n      recipient(azure_id: $azureId, email: $email) {\n        _id\n        canEdit\n        type\n        name\n        profile_pic\n        outlook\n        title\n        directDial\n        verified\n        verifiedByName\n        verifiedOn\n        groupMembership {\n          group\n        }\n        companies {\n          _id\n          name\n          type\n        }\n        contacts {\n          _id\n          contact\n          contactType\n          description\n          primary\n          private\n          source\n        }\n      }\n      person {\n        _id\n        directDial\n        profile_pic\n        type\n        verified\n        groupMembership {\n          _id\n          group\n          primary\n        }\n        contacts {\n          contact\n          contactType\n          primary\n          _id\n        }\n      }\n    }\n    message\n    success\n  }\n}\n    ',
      ],
      [
        '\n    mutation UpdateCallRecordsStatus($input: [CallRecordStatusUpdateInput!]!, $email: String!, $azureId: String!) {\n  updateCallRecordsStatus(input: $input) {\n    callRecord {\n      _id\n      deskId\n      recipientType\n      recipientName\n      companyName\n      status\n      occurrence_date\n      contactInfo\n      extension\n      favorite\n      notes {\n        _id\n        note\n      }\n      contact {\n        contact\n        contactType\n        private\n        description\n      }\n      recipient(azure_id: $azureId, email: $email) {\n        _id\n        canEdit\n        type\n        name\n        profile_pic\n        outlook\n        title\n        directDial\n        verified\n        verifiedByName\n        verifiedOn\n        groupMembership {\n          group\n        }\n        companies {\n          _id\n          name\n          type\n        }\n        contacts {\n          _id\n          contact\n          contactType\n          description\n          primary\n          private\n          source\n        }\n      }\n      person {\n        _id\n        directDial\n        profile_pic\n        type\n        verified\n        groupMembership {\n          _id\n          group\n          primary\n        }\n        contacts {\n          contact\n          contactType\n          primary\n          _id\n        }\n      }\n    }\n    message\n    success\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useUpdateCallRecordsStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCallRecordsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallRecordsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallRecordsStatusMutation, { data, loading, error }] = useUpdateCallRecordsStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      email: // value for 'email'
 *      azureId: // value for 'azureId'
 *   },
 * });
 */
function useUpdateCallRecordsStatusMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.UpdateCallRecordsStatusDocument, options);
}
exports.UpdateDeskDocument = (0, client_1.gql)(
  templateObject_7 ||
    (templateObject_7 = __makeTemplateObject(
      [
        '\n    mutation UpdateDesk($id: String!, $input: DeskUpdate!) {\n  updateDesk(_id: $id, input: $input) {\n    message\n    success\n    desk {\n      settings {\n        allowFutureCalls\n        denseMode\n        duplicateWarning\n        hideBulkEdits\n        hideFutureCalls\n      }\n      statuses {\n        _id\n        color\n        deskId\n        status\n        name\n        type\n        order\n        hasCallRecord\n      }\n      name\n      _id\n    }\n  }\n}\n    ',
      ],
      [
        '\n    mutation UpdateDesk($id: String!, $input: DeskUpdate!) {\n  updateDesk(_id: $id, input: $input) {\n    message\n    success\n    desk {\n      settings {\n        allowFutureCalls\n        denseMode\n        duplicateWarning\n        hideBulkEdits\n        hideFutureCalls\n      }\n      statuses {\n        _id\n        color\n        deskId\n        status\n        name\n        type\n        order\n        hasCallRecord\n      }\n      name\n      _id\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useUpdateDeskMutation__
 *
 * To run a mutation, you first call `useUpdateDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeskMutation, { data, loading, error }] = useUpdateDeskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateDeskMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.UpdateDeskDocument, options);
}
exports.UpdateStatusDocument = (0, client_1.gql)(
  templateObject_8 ||
    (templateObject_8 = __makeTemplateObject(
      [
        '\n    mutation UpdateStatus($input: [DeskStatusInput!]!) {\n  updateStatus(input: $input) {\n    message\n    success\n  }\n}\n    ',
      ],
      [
        '\n    mutation UpdateStatus($input: [DeskStatusInput!]!) {\n  updateStatus(input: $input) {\n    message\n    success\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useUpdateStatusMutation__
 *
 * To run a mutation, you first call `useUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatusMutation, { data, loading, error }] = useUpdateStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateStatusMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.UpdateStatusDocument, options);
}
exports.UpdateUserPhoneSheetFiltersDocument = (0, client_1.gql)(
  templateObject_9 ||
    (templateObject_9 = __makeTemplateObject(
      [
        '\n    mutation UpdateUserPhoneSheetFilters($input: PhoneSheetFiltersInput!) {\n  updatePhoneSheetFilters(input: $input) {\n    _id\n    phoneSheetFilter {\n      deskId\n      filter\n      query\n      favorite\n    }\n  }\n}\n    ',
      ],
      [
        '\n    mutation UpdateUserPhoneSheetFilters($input: PhoneSheetFiltersInput!) {\n  updatePhoneSheetFilters(input: $input) {\n    _id\n    phoneSheetFilter {\n      deskId\n      filter\n      query\n      favorite\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useUpdateUserPhoneSheetFiltersMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneSheetFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneSheetFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneSheetFiltersMutation, { data, loading, error }] = useUpdateUserPhoneSheetFiltersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
function useUpdateUserPhoneSheetFiltersMutation(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useMutation(exports.UpdateUserPhoneSheetFiltersDocument, options);
}
exports.CallRecordsDocument = (0, client_1.gql)(
  templateObject_10 ||
    (templateObject_10 = __makeTemplateObject(
      [
        '\n    query CallRecords($where: CallRecordWhere, $order: CallRecordOrder, $take: Int, $skip: Int, $azureId: String!, $email: String!) {\n  callRecords(order: $order, take: $take, skip: $skip, where: $where) {\n    callRecords {\n      _id\n      deskId\n      recipientType\n      recipientName\n      companyName\n      status\n      occurrence_date\n      contactInfo\n      extension\n      favorite\n      notes {\n        _id\n        note\n      }\n      contact {\n        contact\n        contactType\n        private\n        description\n      }\n      recipient(azure_id: $azureId, email: $email) {\n        _id\n        canEdit\n        type\n        name\n        profile_pic\n        outlook\n        title\n        directDial\n        verified\n        verifiedByName\n        verifiedOn\n        groupMembership {\n          group\n        }\n        companies {\n          _id\n          name\n          type\n        }\n        contacts {\n          _id\n          contact\n          contactType\n          description\n          primary\n          private\n          source\n        }\n      }\n      person {\n        _id\n        directDial\n        profile_pic\n        type\n        verified\n        groupMembership {\n          _id\n          group\n          primary\n        }\n        contacts {\n          contact\n          contactType\n          primary\n          _id\n        }\n      }\n    }\n    meta {\n      limit\n      more\n      skip\n      total\n    }\n  }\n}\n    ',
      ],
      [
        '\n    query CallRecords($where: CallRecordWhere, $order: CallRecordOrder, $take: Int, $skip: Int, $azureId: String!, $email: String!) {\n  callRecords(order: $order, take: $take, skip: $skip, where: $where) {\n    callRecords {\n      _id\n      deskId\n      recipientType\n      recipientName\n      companyName\n      status\n      occurrence_date\n      contactInfo\n      extension\n      favorite\n      notes {\n        _id\n        note\n      }\n      contact {\n        contact\n        contactType\n        private\n        description\n      }\n      recipient(azure_id: $azureId, email: $email) {\n        _id\n        canEdit\n        type\n        name\n        profile_pic\n        outlook\n        title\n        directDial\n        verified\n        verifiedByName\n        verifiedOn\n        groupMembership {\n          group\n        }\n        companies {\n          _id\n          name\n          type\n        }\n        contacts {\n          _id\n          contact\n          contactType\n          description\n          primary\n          private\n          source\n        }\n      }\n      person {\n        _id\n        directDial\n        profile_pic\n        type\n        verified\n        groupMembership {\n          _id\n          group\n          primary\n        }\n        contacts {\n          contact\n          contactType\n          primary\n          _id\n        }\n      }\n    }\n    meta {\n      limit\n      more\n      skip\n      total\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useCallRecordsQuery__
 *
 * To run a query within a React component, call `useCallRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallRecordsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      azureId: // value for 'azureId'
 *      email: // value for 'email'
 *   },
 * });
 */
function useCallRecordsQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useQuery(exports.CallRecordsDocument, options);
}
function useCallRecordsLazyQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(exports.CallRecordsDocument, options);
}
function useCallRecordsSuspenseQuery(baseOptions) {
  var options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useSuspenseQuery(exports.CallRecordsDocument, options);
}
exports.DeskStatusesDocument = (0, client_1.gql)(
  templateObject_11 ||
    (templateObject_11 = __makeTemplateObject(
      [
        '\n    query DeskStatuses($id: String) {\n  desks(where: {_id: $id}) {\n    statuses {\n      _id\n      color\n      deskId\n      status\n      name\n      type\n      order\n      hasCallRecord\n    }\n  }\n}\n    ',
      ],
      [
        '\n    query DeskStatuses($id: String) {\n  desks(where: {_id: $id}) {\n    statuses {\n      _id\n      color\n      deskId\n      status\n      name\n      type\n      order\n      hasCallRecord\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useDeskStatusesQuery__
 *
 * To run a query within a React component, call `useDeskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useDeskStatusesQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useQuery(exports.DeskStatusesDocument, options);
}
function useDeskStatusesLazyQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(exports.DeskStatusesDocument, options);
}
function useDeskStatusesSuspenseQuery(baseOptions) {
  var options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useSuspenseQuery(exports.DeskStatusesDocument, options);
}
exports.DeskDocument = (0, client_1.gql)(
  templateObject_12 ||
    (templateObject_12 = __makeTemplateObject(
      [
        '\n    query Desk($id: String) {\n  desks(where: {_id: $id}) {\n    _id\n    name\n    owner\n    statuses {\n      _id\n      color\n      deskId\n      status\n      name\n      type\n      order\n      hasCallRecord\n    }\n    settings {\n      allowFutureCalls\n      denseMode\n      duplicateWarning\n      hideBulkEdits\n      hideFutureCalls\n      collapseCallForm\n    }\n  }\n}\n    ',
      ],
      [
        '\n    query Desk($id: String) {\n  desks(where: {_id: $id}) {\n    _id\n    name\n    owner\n    statuses {\n      _id\n      color\n      deskId\n      status\n      name\n      type\n      order\n      hasCallRecord\n    }\n    settings {\n      allowFutureCalls\n      denseMode\n      duplicateWarning\n      hideBulkEdits\n      hideFutureCalls\n      collapseCallForm\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useDeskQuery__
 *
 * To run a query within a React component, call `useDeskQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useDeskQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useQuery(exports.DeskDocument, options);
}
function useDeskLazyQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(exports.DeskDocument, options);
}
function useDeskSuspenseQuery(baseOptions) {
  var options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useSuspenseQuery(exports.DeskDocument, options);
}
exports.GetPeopleDocument = (0, client_1.gql)(
  templateObject_13 ||
    (templateObject_13 = __makeTemplateObject(
      [
        '\n    query GetPeople($where: PersonWhere, $take: Int, $skip: Int) {\n  getPeople(where: $where, take: $take, skip: $skip) {\n    people {\n      _id\n      canEdit\n      directDial\n      email\n      hasPhoneOrEmail\n      name\n      phone\n      profile_pic\n      title\n      type\n      verified\n      verifiedOn\n      verifiedByName\n      companies {\n        _id\n        name\n        type\n      }\n      contacts {\n        _id\n        contact\n        contactType\n        description\n        primary\n        private\n        source\n      }\n    }\n    meta {\n      limit\n      more\n      skip\n      total\n    }\n  }\n}\n    ',
      ],
      [
        '\n    query GetPeople($where: PersonWhere, $take: Int, $skip: Int) {\n  getPeople(where: $where, take: $take, skip: $skip) {\n    people {\n      _id\n      canEdit\n      directDial\n      email\n      hasPhoneOrEmail\n      name\n      phone\n      profile_pic\n      title\n      type\n      verified\n      verifiedOn\n      verifiedByName\n      companies {\n        _id\n        name\n        type\n      }\n      contacts {\n        _id\n        contact\n        contactType\n        description\n        primary\n        private\n        source\n      }\n    }\n    meta {\n      limit\n      more\n      skip\n      total\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useGetPeopleQuery__
 *
 * To run a query within a React component, call `useGetPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
function useGetPeopleQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useQuery(exports.GetPeopleDocument, options);
}
function useGetPeopleLazyQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(exports.GetPeopleDocument, options);
}
function useGetPeopleSuspenseQuery(baseOptions) {
  var options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useSuspenseQuery(exports.GetPeopleDocument, options);
}
exports.UserPhoneSheetFilterDocument = (0, client_1.gql)(
  templateObject_14 ||
    (templateObject_14 = __makeTemplateObject(
      [
        '\n    query UserPhoneSheetFilter($id: String!) {\n  users(where: {_id: $id}, skip: 0, take: 1) {\n    _id\n    phoneSheetFilter {\n      deskId\n      filter\n      query\n      favorite\n    }\n  }\n}\n    ',
      ],
      [
        '\n    query UserPhoneSheetFilter($id: String!) {\n  users(where: {_id: $id}, skip: 0, take: 1) {\n    _id\n    phoneSheetFilter {\n      deskId\n      filter\n      query\n      favorite\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useUserPhoneSheetFilterQuery__
 *
 * To run a query within a React component, call `useUserPhoneSheetFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPhoneSheetFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPhoneSheetFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
function useUserPhoneSheetFilterQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useQuery(exports.UserPhoneSheetFilterDocument, options);
}
function useUserPhoneSheetFilterLazyQuery(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useLazyQuery(exports.UserPhoneSheetFilterDocument, options);
}
function useUserPhoneSheetFilterSuspenseQuery(baseOptions) {
  var options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useSuspenseQuery(exports.UserPhoneSheetFilterDocument, options);
}
exports.DeskMutatedDocument = (0, client_1.gql)(
  templateObject_15 ||
    (templateObject_15 = __makeTemplateObject(
      [
        '\n    subscription DeskMutated($deskId: String!) {\n  deskMutated(deskId: $deskId) {\n    _id\n    name\n    settings {\n      allowFutureCalls\n      denseMode\n      duplicateWarning\n      hideBulkEdits\n      hideFutureCalls\n    }\n    statuses {\n      _id\n      color\n      deskId\n      status\n      name\n      type\n      order\n      hasCallRecord\n    }\n  }\n}\n    ',
      ],
      [
        '\n    subscription DeskMutated($deskId: String!) {\n  deskMutated(deskId: $deskId) {\n    _id\n    name\n    settings {\n      allowFutureCalls\n      denseMode\n      duplicateWarning\n      hideBulkEdits\n      hideFutureCalls\n    }\n    statuses {\n      _id\n      color\n      deskId\n      status\n      name\n      type\n      order\n      hasCallRecord\n    }\n  }\n}\n    ',
      ]
    ))
);
/**
 * __useDeskMutatedSubscription__
 *
 * To run a query within a React component, call `useDeskMutatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeskMutatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeskMutatedSubscription({
 *   variables: {
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
function useDeskMutatedSubscription(baseOptions) {
  var options = __assign(__assign({}, defaultOptions), baseOptions);
  return Apollo.useSubscription(exports.DeskMutatedDocument, options);
}
var templateObject_1,
  templateObject_2,
  templateObject_3,
  templateObject_4,
  templateObject_5,
  templateObject_6,
  templateObject_7,
  templateObject_8,
  templateObject_9,
  templateObject_10,
  templateObject_11,
  templateObject_12,
  templateObject_13,
  templateObject_14,
  templateObject_15;
